import type { RowData, Row, ColumnDef } from '@tanstack/react-table';

import parseLinkHeader from 'parse-link-header';
import React, { useState, useCallback, useMemo, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useRealtimeStore } from '@core/store/Realtime';
import type { APIKeyRequestRow, ListResponse, HeaderLinks } from '@core/types/metrics';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import ReactTable, { useTablePreferences } from '@ui/ReactTable';
import type { ColumnSort } from '@ui/ReactTable/types';

import classes from './style.module.scss';
import { defaultColumn, tablePrefs } from './tableModels';

interface Props {
  highlightRowOnClick?: boolean;
  isLoggedIn: boolean;
  onRowClick?: (row: Row<APIKeyRequestRow>) => void;
}

// @tanstack/react-table types available: packages/react/node_modules/@tanstack/table-core/build/types/index.d.ts
const MyRequestsTable = ({ highlightRowOnClick, isLoggedIn, onRowClick }: Props) => {
  const bem = useClassy(classes, 'MyRequestsTable');

  const [filters, getFiltersQueryString, getHasChangedFromInitialFilters, isReady, updateFilters] = useRealtimeStore(
    state => [
      state.filters,
      state.getFiltersQueryString,
      state.getHasChangedFromInitialFilters,
      state.isReady,
      state.updateFilters,
    ],
  );

  const filtersHaveChanged = getHasChangedFromInitialFilters();
  const queryParams = getFiltersQueryString();

  const { direction, page = 0, sort } = filters;

  const {
    columns,
    endpoint,
    sort: defaultSortColumn,
    direction: defaultSortDirection,
    order: defaultColumnOrder,
    visibility: defaultColumnVisibility,
    prefsName,
  } = tablePrefs;

  // Fetch table data from /requests/list
  const {
    data: logs,
    headers,
    error,
    isLoading,
    isValidating,
  } = useMetricsAPI<ListResponse>(`${endpoint}?${queryParams}`, isReady);

  const { columnOrder, setColumnOrder, columnVisibility, setColumnVisibility } = useTablePreferences({
    defaultOrder: defaultColumnOrder,
    defaultVisibility: defaultColumnVisibility,
    prefsName,
  });

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (!isLoading && !logs?.length) return;

    try {
      const { link } = headers;
      const { last } = parseLinkHeader(link) as unknown as HeaderLinks;

      const parsedTotalPageCount = parseInt(last?.page, 10);
      if (!Number.isNaN(parsedTotalPageCount)) setTotalPages(parsedTotalPageCount + 1);
      else setTotalPages(1);
    } catch (e) {
      // do nothing
    }
  }, [isLoading, headers, logs]);

  // Set pagination from response
  useEffect(() => {
    if (isLoading || !logs?.length) return;

    setTotalPages(1);
  }, [isLoading, logs]);

  const updatePage = useCallback(
    selectedPage => {
      updateFilters({
        page: selectedPage,
      });
    },
    [updateFilters],
  );

  const onSortChange = useCallback(
    (newSort: ColumnSort) => {
      updateFilters({ sort: newSort.column, direction: newSort.direction });
    },
    [updateFilters],
  );

  const customEmptyRender = useMemo(() => {
    if (!isLoggedIn) {
      return (
        <Flex align="center" gap="xs">
          <Icon aria-hidden="true" name="key" />
          <span>Log in to see request history</span>
        </Flex>
      );
    }

    return (
      <>
        No Requests
        {!!filtersHaveChanged && <span>Try updating your filters</span>}
      </>
    );
  }, [filtersHaveChanged, isLoggedIn]);

  return (
    <ReactTable
      className={bem('&')}
      columnOptions={{
        visibility: columnVisibility,
        order: columnOrder,
      }}
      columns={columns as ColumnDef<RowData>[]}
      customEmptyRender={customEmptyRender}
      data={logs || []}
      defaultColumn={defaultColumn as Partial<ColumnDef<RowData>>}
      hasError={!!error}
      highlightRowOnClick={highlightRowOnClick}
      includeColumnSelector
      isCompact
      isLoading={isLoading || isValidating}
      layout="auto"
      onColumnOrderChange={setColumnOrder}
      onColumnVisibilityChange={setColumnVisibility}
      onPageChange={updatePage}
      onRowClick={onRowClick}
      onSort={onSortChange}
      page={page || 0}
      pages={totalPages}
      sort={{
        column: sort || defaultSortColumn,
        direction: direction || defaultSortDirection,
      }}
      transparentHeader
    />
  );
};

export default MyRequestsTable;
