import type { $TSFixMe } from '@readme/iso';
import type Oas from 'oas';

import { format } from 'date-fns';
import React, { useContext, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import type { ProjectContextValue, VariablesContextValue } from '@core/context';
import { UserContext, ProjectContext, VariablesContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReferenceStore, useSuperHubStore } from '@core/store';

import useAPIKeys from '@routes/Reference/hooks/useAPIKeys';

import SectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import Skeleton from '@ui/Skeleton';
import Sparkline from '@ui/Sparkline';
import Table from '@ui/Table';
import Tooltip from '@ui/Tooltip';

import UserAvatar from '../UserAvatar';

import classes from './style.module.scss';

interface Props {
  oas: Oas;
}

function YourAPIKeys({ oas }: Props) {
  const bem = useClassy(classes, 'YourAPIKeys');
  const { isAdmin } = useContext(UserContext) as $TSFixMe;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { user } = useContext(VariablesContext) as VariablesContextValue;
  const [group, groups, updateGroup, isGroupLoggedIn] = useReferenceStore(s => [
    s.auth.group,
    s.auth.groups,
    s.auth.updateGroup,
    s.auth.isGroupLoggedIn,
  ]);

  const isSuperHub = useSuperHubStore(s => s.isSuperHub);

  const { pathname } = useLocation();
  const { keysSortedByLastUsed } = useAPIKeys({ fetchTrendData: true });

  const isLoggedIn = Boolean(groups && groups.length) || isGroupLoggedIn;

  const onAuthGroupChange = useCallback(
    groupId => {
      updateGroup({ apiDefinition: oas, groupId, user });
    },
    [oas, updateGroup, user],
  );

  const tableHead = [['API Key', 'Label', ['Last Used', 0, 'left', bem('-lastSeen')], '']];
  const tableData = useMemo(
    () =>
      keysSortedByLastUsed?.map(({ apiKey, dataSet, name, labels, lastUsed }, i) => {
        return [
          <ObfuscatedAPIKey
            key={`${i}-apikey`}
            apiKey={apiKey}
            className={bem('-ObfuscatedAPIKey')}
            conceal={'before'}
          />,
          <p key={`${i}-label`} className={bem('-overflow')}>
            <Tooltip content={name}>
              <span>{name}</span>
            </Tooltip>
          </p>,
          <Flex key={`${i}-lastSeen`} align="center" className={bem('-lastSeen')} gap="md" justify="start">
            <span className={bem('-lastSeen-date')}> {lastUsed ? format(new Date(lastUsed), 'M/d/yy') : '-'}</span>

            <div className={bem('-lastSeen-sparkline')}>
              <Sparkline
                data={(dataSet?.data || {}) as Record<string, number[]>}
                height="15px"
                labels={labels || []}
                maxWidth="80px"
              />
            </div>
          </Flex>,
          <>
            {group === apiKey ? (
              <i key={`${i}-apikey-check`} className={bem('-iconCheck', 'icon-check1')} />
            ) : (
              <Tooltip content="Use API Key">
                <Button
                  key={`${i}-apikey-button`}
                  className={bem('-button')}
                  ghost
                  kind={'minimum'}
                  onClick={() => onAuthGroupChange(apiKey)}
                  size={'xs'}
                >
                  <i className="icon-check1" />
                </Button>
              </Tooltip>
            )}
          </>,
        ];
      }),
    [bem, keysSortedByLastUsed, group, onAuthGroupChange],
  );

  if (!isLoggedIn) {
    return (
      <Flex className={bem('&')} gap="sm" layout="col">
        <Button
          className={bem('-empty-button')}
          href={`/login?redirect_uri=${pathname}`}
          kind="secondary"
          size="xs"
          text
        >
          <Icon aria-hidden="true" name="key" />
          Log in to see your API keys
        </Button>
        <Box className={bem('-box')} kind="rule">
          <Table
            body={[
              [
                /* eslint-disable react/jsx-key */
                <Skeleton height={10} width={85} />,
                <Skeleton height={10} width={85} />,
                <Skeleton height={10} width={60} />,
                <></>,
              ],

              [
                <Skeleton height={10} width={85} />,
                <Skeleton height={10} width={85} />,
                <Skeleton height={10} width={60} />,
                <></>,
                /* eslint-enable react/jsx-key */
              ],
            ]}
            className={bem('-table', '-table_empty')}
            head={tableHead}
          />
        </Box>
      </Flex>
    );
  }

  return (
    <section className={bem('&')}>
      <SectionHeader
        heading={
          <Flex align="center" gap="xs" justify="start">
            <UserAvatar /> Your API Keys
          </Flex>
        }
      />
      <Box className={bem('-box')} kind="rule">
        {keysSortedByLastUsed?.length ? (
          <Table body={tableData} className={bem('-table')} head={tableHead} />
        ) : (
          <Flex align="center" className={bem('-empty-prompt')} gap="sm" justify="center">
            <Icon aria-hidden="true" className={bem('-empty-prompt-icon')} name="key" />
            {!!project.webhookEnabled && <span>No API keys found.</span>}
            {!isAdmin && !project.webhookEnabled && <span>API keys are not synced with this developer hub.</span>}
            {!!isAdmin && !project.webhookEnabled && (
              <span>
                {isSuperHub ? (
                  <>
                    Set up{' '}
                    <Button
                      link
                      onClick={() => {
                        window.location.hash = '/content/personalized-docs/setup';
                      }}
                      style={{
                        color: 'inherit',
                      }}
                    >
                      Personalized Docs
                    </Button>{' '}
                    to show users their API keys.
                  </>
                ) : (
                  <>
                    Set up Personalized Docs in your <a href="https://dash.readme.com/">dashboard</a> to show users
                    their API keys.
                  </>
                )}
              </span>
            )}
          </Flex>
        )}
      </Box>
    </section>
  );
}

export default YourAPIKeys;
