import Oas from 'oas';
import { Operation, Webhook } from 'oas/operation';
import { useContext, useEffect, useMemo, useState } from 'react';

import { VariablesContext } from '@core/context';

function getPath(oas, doc) {
  if (!oas.api?.paths || !doc?.swagger) {
    return { parameters: [] };
  }

  return oas.api.paths[doc.swagger.path];
}

function getOperationOrWebhook(oas, doc) {
  let operation = null;

  if (doc?.swagger && (doc.type === 'api_config' || doc.type === 'endpoint' || doc.type === 'webhook')) {
    // This is the same path determination logic we use when we create fake OpenAPI definitions for
    // manual API users. See `APISetting.fauxOas()` for that work.
    let apiPath = doc.swagger.path;
    if (oas.api?.['x-readme-fauxas']) {
      // Some manual API users save their path parameters as `:parameter` instead of the OAS
      // proper way of `{parameter}`. We make this adjustment when we generate their faux OAS
      // for the page, and it seems that sometimes this change exists in `doc.swagger` but
      // because we're always prioritizing `doc.api` over `doc.swagger` (as the manual API editor
      // saves changes to `doc.api` but only updates `doc.swagger` on creation) we need to also
      // do this repair work here before we do a lookup in their faux manual API generated OAS.
      //
      // This code was pulled over from `Page.replacePathParams`. It'd be nice if it lived in a
      // shared OAS helper method, but it'd be even nicer if our manual API data storage wasn't
      // such a mess that we needed to do this at all.
      apiPath = doc.api.url.replace(/:(.[^/&?]+)/g, (match, value) => {
        if (doc.api.params.filter(e => e.name === value).length > 0) {
          return `{${value}}`;
        }
        return match;
      });

      if (doc?.version?.version) {
        apiPath = apiPath.replace(/{version}/g, doc.version.version);
      }
    }

    operation = oas.operation(apiPath, doc.api.method, doc.type === 'webhook' ? { isWebhook: true } : {});
  }

  // If for whatever reason we don't have this operation in their OAS let's do our best to give them _something_. Note
  // that this doesn't account for if webhooks are present for OpenAPI 3.1 definitions because we don't yet support
  // webhooks.
  if (!operation && !getPath(oas, doc)) {
    if (doc.type === 'webhook') {
      operation = new Webhook(oas, doc.api.url, doc.api.method, {
        parameters: doc.api.params,
      });
    } else {
      operation = new Operation(oas, doc.api.url, doc.api.method, {
        parameters: doc.api.params,
      });
    }
  }

  return operation;
}

/**
 * With an API definition and a doc object, load and dereference an OAS instance.
 *
 * @param {object} apiDefinition The OpenAPI definition to utilize.
 * @param {Page} doc
 * @param {Oas} initialOas This is an optional parameter that lets you pass in a pre-made oas object, instead of building one off the apiDefinition.
 */
const useOas = (apiDefinition, doc) => {
  const variables = useContext(VariablesContext);

  const [dereferencingError, setDereferencingError] = useState(false);
  // If an initialOas is provided, we don't have to do any loading or dereferencing so we can default isLoading to false.
  const [isLoading, setLoading] = useState(true);

  // Reason we're disabling the exhaustive deps rule here is because `useMemo` doesn't do deep-object comparisons on the
  // `oas` and `doc` objects, so we're instead passing the id into it to bust the memoized cache.
  /* eslint-disable react-hooks/exhaustive-deps */
  const oas = useMemo(() => new Oas(structuredClone(apiDefinition), variables.user), [apiDefinition?._id, variables]);
  const operation = useMemo(() => getOperationOrWebhook(oas, doc), [doc?._id, oas.api?._id]);

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        return;
      }

      try {
        await oas.dereference();
        setLoading(false);
      } catch (err) {
        setDereferencingError(err);
      }
    })();
  }, [isLoading, oas?.api?._id]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return { oas, operation, dereferencingError, isLoading };
};

export default useOas;
