import type {
  ApiKeyProps,
  AuthInputProps,
  BaseAuthInputProps,
  BasicAuthProps,
  GroupBadgeProps,
  GroupDropdownProps,
  GroupItemProps,
  OAuth2Props,
} from '../../types';
import type { OpenAPIV3 } from 'openapi-types';

import { getGroupNameById } from '@readme/iso/src/metrics';
import React, { useEffect, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useOAuthFlow from '@core/hooks/useOAuthFlow';
import { useReferenceStore } from '@core/store';
import { supportedOAuthFlows, type SupportedOAuthFlow } from '@core/store/Reference/Auth/oauth-types';

import Badge from '@ui/Badge';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';
import RDMD from '@ui/RDMD';
import Select from '@ui/Select';
import Tooltip from '@ui/Tooltip';

import { InfoTooltip } from '../../components/Info';
import setInputRef from '../../set-input-ref';
import useShowPasswordToggle from '../../useShowPasswordToggle';
import classes from '../style.module.scss';

const labels: Record<string, BaseAuthInputProps['label']> = {
  apiKey: { placeholder: 'X-API-KEY' },
  basic: { placeholder: 'username', placeholder2: 'password', separator: ':' },
  bearer: { placeholder: 'token' },
  oauth2: { placeholder: 'token', prefix: 'Bearer' },
};

const GroupItem = ({ name }: GroupItemProps) => <div className="InputGroup-GroupItem">{name}</div>;

const ApiKey = ({ apiKey, change, inputIndex, inputRef, label, placeholder }: ApiKeyProps) => {
  const { showPassword, ShowPasswordToggle } = useShowPasswordToggle();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    change(e.target.value);
  }

  return (
    <>
      {!!label.prefix && (
        <label className="InputGroup-prefix" htmlFor={`APIAuth-${placeholder || label.placeholder}`}>
          {label.prefix}
        </label>
      )}
      <input
        ref={el => setInputRef(el, inputIndex, inputRef)}
        autoComplete="off"
        className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
        id={`APIAuth-${placeholder || label.placeholder}`}
        onChange={onInputChange}
        placeholder={placeholder || label.placeholder}
        required
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={typeof apiKey === 'string' ? apiKey : ''}
      />
      {ShowPasswordToggle}
    </>
  );
};

const Basic = ({ change, inputIndex, inputRef, label, pass, user }: BasicAuthProps) => {
  const { showPassword, ShowPasswordToggle } = useShowPasswordToggle();

  function inputChange(name: string, val: string) {
    change({ user, pass, [name]: val });
  }

  return (
    <>
      <input
        ref={el => setInputRef(el, inputIndex, inputRef)}
        aria-label={label.placeholder}
        autoComplete="off"
        className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
        data-1p-ignore
        name="user"
        onChange={e => inputChange(e.target.name, e.target.value)}
        placeholder={label.placeholder}
        required
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={user || ''}
      />
      <span className="InputGroup-prefix">{label.separator}</span>
      <input
        aria-label={label.placeholder2}
        autoComplete="off"
        className="InputGroup-input"
        name="pass"
        onChange={e => inputChange(e.target.name, e.target.value)}
        placeholder={label.placeholder2}
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={pass || ''}
      />
      {ShowPasswordToggle}
    </>
  );
};

const OAuth2 = ({ apiKey, change, inputIndex, inputRef, label, scheme }: OAuth2Props) => {
  const bem = useClassy(classes, 'APIAuth');

  const { showPassword, ShowPasswordToggle } = useShowPasswordToggle(false);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    change(e.target.value);
  }

  const [
    availableFlows,
    authorizedScopes,
    authorizedToken,
    clientId,
    clientSecret,
    draftState,
    draftScopes,
    requiredScopes,
    selectedFlow,
    status,
    toggleOAuthScope,
    updateOAuthClient,
    updateOAuthFlow,
    updateOAuthStatus,
  ] = useReferenceStore(s => [
    Object.keys(s.auth.oauth.schemes[scheme._key]?.flows || {}) as SupportedOAuthFlow[],
    s.auth.oauth.schemes[scheme._key]?.finalState?.selectedScopes || [],
    s.auth.oauth.schemes[scheme._key]?.finalState?.response.access_token || '',
    s.auth.oauth.schemes[scheme._key]?.draftState.clientId || '',
    s.auth.oauth.schemes[scheme._key]?.draftState.clientSecret || '',
    s.auth.oauth.schemes[scheme._key]?.draftState || {},
    s.auth.oauth.schemes[scheme._key]?.draftState.selectedScopes || [],
    s.auth.oauth.schemes[scheme._key]?.requiredScopes,
    s.auth.oauth.schemes[scheme._key]?.draftState.selectedFlow,
    s.auth.oauth.schemes[scheme._key]?.draftState.status || 'not-started',
    s.auth.toggleOAuthScope,
    s.auth.updateOAuthClient,
    s.auth.updateOAuthFlow,
    s.auth.updateOAuthStatus,
  ]);

  const errorMessage = draftState?.status === 'failure' && draftState.error;

  const clientIdRef = useRef<HTMLInputElement>(null);

  const { submitClientCredentials } = useOAuthFlow({ securitySchemeKey: scheme._key, source: 'original' });

  const allScopesForCurrentFlow = useReferenceStore(
    s => s.auth.oauth.schemes[scheme._key]?.flows[selectedFlow]?.scopes || {},
  );

  const [displayClientAuth, setDisplayClientAuth] = useState(true);

  useEffect(() => {
    if (status === 'done') {
      setDisplayClientAuth(false);
    }
  }, [status]);

  useEffect(() => {
    if (apiKey) {
      setDisplayClientAuth(false);
    }
    // this effect should only run once, when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const missingAuthorizedScopeCount = requiredScopes?.filter(scope => !authorizedScopes.includes(scope)).length;
  const missingDraftScopeCount = requiredScopes?.filter(scope => !draftScopes.includes(scope)).length;

  const tokenDetailsTooltip = (
    <div>
      <b>Client ID: </b>
      {`${clientId}`}
      <br />
      <br />
      {authorizedScopes.length ? (
        <>
          <b>Authorized scopes for this token: </b>
          <ul className={bem('-oauth-authorized-scopes-tooltip')}>
            {authorizedScopes?.map(scope => <li key={scope}>{scope}</li>)}
          </ul>
        </>
      ) : (
        <>
          <b>Token has no authorized scopes</b>
          <br />
        </>
      )}
      <br />
      {requiredScopes?.length ? (
        <>
          <b>
            Scopes required for this operation
            {missingAuthorizedScopeCount ? ` (${missingAuthorizedScopeCount} missing from this token)` : ''}:
          </b>
          <ul className={bem('-oauth-authorized-scopes-tooltip')}>
            {requiredScopes.map(scope => (
              <li key={scope} className={bem(!authorizedScopes.includes(scope) ? '-oauth-missing-scope' : '')}>
                {scope}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <b>No scopes required for this operation</b>
      )}
    </div>
  );

  const requiredScopesTooltip = (
    <div>
      {requiredScopes?.length ? (
        <>
          {missingDraftScopeCount
            ? `This operation requires ${missingDraftScopeCount} additional ${(missingDraftScopeCount as number) > 1 ? 'scopes' : 'scope'}:`
            : 'Required scopes for this operation:'}
          <ul className={bem('-oauth-required-scopes-tooltip')}>
            {requiredScopes.map(scope => (
              <li key={scope} className={bem(!draftScopes.includes(scope) ? '-oauth-missing-scope' : '')}>
                {scope}
              </li>
            ))}
          </ul>
        </>
      ) : (
        'No scopes required for this operation'
      )}
    </div>
  );

  return displayClientAuth ? (
    <Flex align="stretch" gap="xs" layout="col" tag="form">
      {availableFlows?.length > 1 ? (
        <Select
          aria-label="Grant Type"
          defaultValue={selectedFlow}
          onChange={e => {
            updateOAuthFlow(scheme._key, e.target.value as SupportedOAuthFlow);
          }}
          options={availableFlows.map(flow => ({
            label: supportedOAuthFlows[flow],
            value: flow,
          }))}
          size="sm"
        />
      ) : null}
      <Flex align="center" className="InputGroup-input-parent InputGroup-oauth-flow-client" gap="0">
        <label className="InputGroup-prefix" htmlFor={`APIAuth-${label.placeholder}`}>
          {'Client'}
        </label>
        <input
          ref={clientIdRef}
          autoComplete="off"
          className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
          id={'APIAuth-oauth-flows-client-id'}
          onChange={e => {
            updateOAuthClient(scheme._key, { clientId: e.target.value, type: 'id' });
          }}
          placeholder={'id'}
          required
          spellCheck="false"
          type={'text'}
          value={clientId}
        />
        <input
          autoComplete="off"
          className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
          id={'APIAuth-oauth-flows-client-secret'}
          onChange={e => {
            updateOAuthClient(scheme._key, { clientSecret: e.target.value, type: 'secret' });
          }}
          placeholder={'secret'}
          required
          spellCheck="false"
          type={showPassword ? 'text' : 'password'}
          value={clientSecret}
        />
        {ShowPasswordToggle}
      </Flex>
      {errorMessage ? <div className={bem('-oauth-error')}>{errorMessage}</div> : null}
      <Flex align="center" className={bem('-oauth-scopes')} justify="between">
        <Dropdown justify="start" sticky>
          <Button dropdown ghost kind="secondary" size="xs" uppercase={false}>
            <Flex align="center" gap="xs" suppressHydrationWarning>
              Scopes
              {!!draftScopes.length && <Badge>{draftScopes.length}</Badge>}
            </Flex>
          </Button>
          <Menu className={bem('-oauth-menu')}>
            {Object.entries(allScopesForCurrentFlow).map(([scope, scopeDescription]) => (
              <MenuItem
                key={scope}
                active={draftScopes.includes(scope)}
                description={scopeDescription}
                onClick={() => {
                  toggleOAuthScope(scheme._key, scope);
                }}
              >
                <div className={bem('-oauth-scopes-selector')}>
                  <span className={bem('-oauth-scopes-text')}>{scope}</span>
                  {!!requiredScopes?.includes(scope) && <div className={bem('-oauth-scopes-required')}>required</div>}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
        <Flex gap="xs" justify="start">
          <Tooltip content={requiredScopesTooltip} interactive={true} touch={true}>
            <div className={bem('-oauth-required-scopes-label')}>
              {missingDraftScopeCount ? (
                <Icon className={bem('-oauth-scopes-icon')} name="alert-triangle"></Icon>
              ) : null}
              {missingDraftScopeCount
                ? `Missing ${missingDraftScopeCount} required ${(missingDraftScopeCount as number) > 1 ? 'scopes' : 'scope'}`
                : 'Required Scopes'}
            </div>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex align="stretch" className={bem('-oauth-footer')} gap="xs" layout="col" tag="footer">
        <Button
          className={bem('-oauth-footer-authorize')}
          loading={status === 'pending' || status === 'received'}
          onClick={() => {
            // validate the presence of a client ID prior to submission
            if (clientIdRef?.current) {
              const isValid = clientIdRef.current.reportValidity();
              if (!isValid) return;
            }
            submitClientCredentials();
          }}
          size="sm"
          type="submit"
        >
          Authorize
        </Button>
        <Button
          ghost
          kind="minimum"
          onClick={() => {
            setDisplayClientAuth(!displayClientAuth);
            if (status === 'pending') updateOAuthStatus(scheme._key, { state: '', status: 'not-started' });
          }}
          size="sm"
        >
          Use Your Own Token
        </Button>
      </Flex>
    </Flex>
  ) : (
    <Flex align="stretch" gap="xs" layout="col" tag="form">
      <Flex align="center" className="InputGroup-input-parent InputGroup-oauth-flow-token" gap="0">
        {!!label.prefix && (
          <label className="InputGroup-prefix" htmlFor={`APIAuth-${label.placeholder}`}>
            {label.prefix}
          </label>
        )}
        <input
          ref={el => setInputRef(el, inputIndex, inputRef)}
          autoComplete="off"
          className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
          data-oauth-scheme={scheme._key}
          id={`APIAuth-${label.placeholder}`}
          onChange={onInputChange}
          placeholder={label.placeholder}
          required
          spellCheck="false"
          type={showPassword ? 'text' : 'password'}
          value={typeof apiKey === 'string' ? apiKey : ''}
        />
        {ShowPasswordToggle}
      </Flex>
      <Flex
        align="center"
        className={bem('-oauth-footer', '-oauth-footer_mini')}
        gap="xs"
        justify="between"
        tag="footer"
      >
        <Flex className={bem('-oauth-authorized-scopes')} gap="xs" justify="start">
          {authorizedToken === apiKey ? (
            <Tooltip content={tokenDetailsTooltip} interactive={true} touch={true}>
              <div className={bem('-oauth-authorized-scopes-container')}>
                <div className={bem('-oauth-authorized-scopes-label')}>Token Details</div>
                <Icon
                  className={bem('-oauth-scopes-icon')}
                  name={missingAuthorizedScopeCount ? 'alert-triangle' : 'info'}
                />
              </div>
            </Tooltip>
          ) : null}
        </Flex>
        <Button
          className={bem('-oauth-footer-reauthorize')}
          ghost
          onClick={() => setDisplayClientAuth(!displayClientAuth)}
          size="xs"
          type="button"
          uppercase={false}
        >
          Re-Authorize
        </Button>
      </Flex>
    </Flex>
  );
};

const GroupBadge = ({ group, groups }: GroupBadgeProps) => {
  const groupNameMatch = getGroupNameById(groups, group);
  const name = groupNameMatch || group;
  const content = <RDMD>{`Credentials for\n\`${name}\``}</RDMD>;

  return (
    <Tooltip content={content}>
      <div className="InputGroup-badge" title={name}>
        <GroupItem name={name} />
      </div>
    </Tooltip>
  );
};

const GroupDropdown = ({ group, groups, onSelect }: GroupDropdownProps) => {
  const bem = useClassy(classes, 'APIAuth');
  if (groups && groups.length)
    return (
      <Menu>
        <MenuHeader className={bem('-menu-header')}>Select Credentials</MenuHeader>
        {groups.map(item => (
          <MenuItem
            key={item.id}
            aria-selected={group === item.id}
            className={bem('-menu-item')}
            onClick={() => onSelect(item.id)}
            role="option"
          >
            <GroupItem name={item.name || item.id} />
          </MenuItem>
        ))}
      </Menu>
    );
  return null;
};

function AuthInput(props: AuthInputProps) {
  const { auth, displayTooltip, group, groups, inputIndex, inputRef, oauth, onAuthGroupChange, onChange, security } =
    props;
  let input;
  const scheme = security.security;
  const change = value => onChange({ [scheme._key]: value });

  // Because OAS validation doesn't throw when `scheme` is here when it shouldn't be, there's a possibility that it's
  // here and also an invalid value. If we have it, but don't recognize it we should always fallback to the set `type`
  // so as to not kill the page when attempting later to look for `label.placeholder` and `label.prefix`.
  const label =
    'scheme' in scheme && scheme.scheme && scheme.scheme in labels ? labels[scheme.scheme] : labels[scheme.type];

  const defaultProps = { change, inputIndex, inputRef, label } as const;

  const oAuthFlowsEnabled =
    oauth === 'enabled' &&
    security.security.type === 'oauth2' &&
    // Check if any of the supported OAuth flow types are present in the security scheme
    Object.keys(security.security.flows).some(flow => Object.keys(supportedOAuthFlows).includes(flow));

  switch (scheme.type) {
    case 'apiKey':
      input = (
        <ApiKey
          apiKey={auth[scheme._key] as string}
          placeholder={(scheme as OpenAPIV3.ApiKeySecurityScheme).name}
          {...defaultProps}
        />
      );
      break;
    case 'oauth2':
      if (oAuthFlowsEnabled) {
        input = <OAuth2 apiKey={auth[scheme._key] as string} scheme={scheme} {...defaultProps} />;
      } else {
        input = <ApiKey apiKey={auth[scheme._key] as string} {...defaultProps} />;
      }
      break;
    case 'http':
      if (scheme.scheme === 'basic') {
        const currentAuth = auth[scheme._key];
        const user =
          !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.user === 'string'
            ? currentAuth.user
            : '';
        const pass =
          !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.pass === 'string'
            ? currentAuth.pass
            : '';

        input = <Basic pass={pass} user={user} {...defaultProps} />;
      }
      if (scheme.scheme === 'bearer') input = <ApiKey apiKey={auth[scheme._key] as string} {...defaultProps} />;
      break;
    default:
      return null;
  }

  const InputFieldWrapper = (
    <div
      className={['InputGroup-dropdown-inputs', oAuthFlowsEnabled ? 'InputGroup-dropdown-inputs_oauth' : ''].join(' ')}
    >
      {input}
      {!!displayTooltip && <InfoTooltip oAuthFlowsEnabled={oAuthFlowsEnabled} security={security} showBadges />}
      {!!group && !!groups && groups.length > 0 && <GroupBadge group={group} groups={groups} />}
    </div>
  );

  return (
    <div className={['InputGroup-input-parent', oAuthFlowsEnabled ? 'InputGroup-input-parent_oauth' : ''].join(' ')}>
      {/* Don't render the dropdown if a user is logged in with a single auth group */}
      {group && (!groups || groups.length === 0) ? (
        InputFieldWrapper
      ) : (
        <>
          <Dropdown className="InputGroup-dropdown" clickInToClose trigger="click">
            {React.cloneElement(InputFieldWrapper, { role: 'combobox' })}
            <Box kind="pop" style={{ padding: 0, width: 'max-content' }}>
              <GroupDropdown group={group} groups={groups} onSelect={onAuthGroupChange} />
            </Box>
          </Dropdown>
        </>
      )}
    </div>
  );
}

export default React.memo(AuthInput);
